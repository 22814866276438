<template>
  <v-row>
    <v-expansion-panels
      v-model="panelTotalesAux"
      light
      class="totales-expansion-panels l-5 v-card v-sheet v-sheet--outlined theme--light elevation-0 rounded-0 mx-3 mt-3 mb-2"
      multiple
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="py-0 pl-3 pr-5" justify-self-start>
          <template v-slot:actions>
            <v-icon class="expansion-panel-icon py-3">
              mdi-chevron-down
            </v-icon>
          </template>
          <v-row class="expansion-panel-header pl-3">
            <h5>
              TOTALES: OPEX CAMPO, OPEX TI, OPEX G. ADMINISTRATIVO, OPEX PACKING, CAPEX / COMPARATIVA PRESUPUESTOS
            </h5>
          </v-row>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content class="pr-0 mt-5">
          <v-row>
            <v-col cols="9" sm="9" lg="9">
              <v-row
                class="expansion-panel-header px-2 pr-5"
                style="margin-left: 16px;"
                v-for="(item, index) in totalesPorSede"
                :key="index"
              >
                <v-col
                  cols="3"
                  sm="3"
                  lg="3"
                  class="px-1 py-0 d-flex"
                  v-for="(item2, index2) in item.PptAprobado"
                  :key="index2"
                >
                  <v-card tile :class="`w-100 mr-1 grey lighten-4 `">
                    <v-card-title
                      style="line-height: 1.5rem; font-size: 10px;"
                      :class="`primary white--text py-0 px-2 d-flex justify-center ${index2 != 0 ? 'lighten-1' : ''}`"
                    >
                      {{ item2.titulo }}
                    </v-card-title>
                    <v-card-text :class="`pb-0 pt-1`">
                      <span
                        class="d-flex justify-center black--text"
                        :style="`font-size: 16px; font-weight: ${index2 != 0 ? '400' : '450'};`"
                      >
                        {{ item2.valor }}
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-divider class="mx-3" vertical v-if="index2 == 0"></v-divider>
                </v-col>
                <v-divider v-if="index < totalesPorSede.length - 1" class="ml-1 my-3"></v-divider>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="3" sm="3" lg="3">
              <v-row class="expansion-panel-header pr-5 pl-3" v-for="(item, index) in totalesComparativa" :key="index">
                <v-col cols="12" sm="12" lg="12" class="mx-1 pr-4 py-0 d-flex">
                  <v-card tile class="grey lighten-4 w-100">
                    <v-card-title
                      style="line-height: 1.5rem; font-size: 10px;"
                      :class="`primary white--text py-0 px-2 d-flex justify-center`"
                    >
                      {{ item.titulo }}
                    </v-card-title>
                    <v-card-text class="py-2">
                      <span
                        class="font-weight-bold green--text d-flex justify-center"
                        :style="`font-size: ${item.objetivo == '' ? '30' : '26'}px;`"
                      >
                        {{ item.valor }}
                      </span>
                      <span class="font-weight-black d-flex justify-center" style="font-size: 12px;">
                        {{ item.objetivo }}
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-divider v-if="index < totalesComparativa.length - 1" class="mx-3 my-3"></v-divider>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
export default {
  name: 'TotalesPresupuesto',
  props: {
    panelTotales: {
      type: Array,
      default: () => []
    },
    totalesPorSede: {
      type: Array,
      default: () => []
    },
    totalesComparativa: {
      type: Array,
      default: () => []
    }
  },
  watch: {},
  computed: {
    panelTotalesAux: {
      get() {
        return this.panelTotales;
      },
      set(val) {
        this.$emit('expand', val);
      }
    }
  }
};
</script>
